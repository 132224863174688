import React, { useRef, useState } from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import { InfinitySpin } from 'react-loader-spinner'; // Importamos el spinner

function ContactContent() {
    const [loading, setLoading] = useState(false); // Estado para mostrar el spinner
    const form = useRef();

    const handleButtonYoutube = () => {
        window.open('https://www.youtube.com/channel/UCTBxxvzMkx8iEwuDEitBP8g', '_blank');
    };

    const handleButtonClick = () => {
        window.open('https://www.linkedin.com/company/bccy-colombia/jobs/?viewAsMember=true', '_blank');
    };

    const handleWhatsappButtonClick = () => {
        const whatsappNumber = '3160267867';
        const message = encodeURIComponent('Hola, estoy interesado en contactarme contigo.');
        window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`, '_blank');
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        setLoading(true); // Activa el spinner antes de enviar
    
        const formData = {
            user_name: form.current.user_name.value,
            user_email: form.current.user_email.value,
            message: form.current.message.value,
            location: "Cordoba"
        };
    
        try {
            const response = await fetch("https://bccycolombia.com/backend/send_email.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
    
            const result = await response.json();
    
            if (result.status === "success") {
                toast.success("El correo se envió correctamente ✅");
                form.current.reset();
            } else {
                toast.error("Error al enviar el correo ❌");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Ocurrió un error ❌");
        } finally {
            setLoading(false); // Desactiva el spinner después de recibir la respuesta
        }
    };

    return (
        <div className="container1">
            <Toaster position="top-right" reverseOrder={false} />

            {/* Spinner de carga en toda la pantalla */}
            {loading && (
                <div className="loading-overlay">
                    <InfinitySpin width="200" color="#00A9BA" />
                </div>
            )}

            <div className="box-info">
                <h1 className='h1contact'>CONTÁCTENOS CÓRDOBA</h1>
                <div className="data">
                    <p><FaPhone /> +57 601 756 9677</p>
                    <p><FaEnvelope /> bccycolombiasas@gmail.com</p>
                    <p><FaMapMarkerAlt /> Calle 98 # 9a 41</p>
                </div>
                <div className="links">
                <button onClick={handleButtonYoutube} className="icon-button">
                <FontAwesomeIcon icon={faYoutube} />
            </button>
            <button onClick={handleButtonClick} className="icon-button">
                <FontAwesomeIcon icon={faLinkedin} />
            </button>
            <button onClick={handleWhatsappButtonClick} className="icon-button">
                <FontAwesomeIcon icon={faWhatsapp} />
            </button>
                </div>
            </div>

            <form ref={form} onSubmit={sendEmail}>
                <div className="input-box">
                    <input type="text" name="user_name" placeholder="Tu nombre" required />
                </div>
                <div className="input-box">
                    <input type="email" name="user_email" placeholder="Tu correo" required />
                </div>
                <div className="input-box">
                    <textarea name="message" placeholder="Tu mensaje" required></textarea>
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? "Enviando..." : "Enviar Mensaje"}
                </button>
            </form>
        </div>
    );
}

export default ContactContent;
