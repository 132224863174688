import React, { useState } from 'react';
import Modal from 'react-modal';
import './Cards.css';
import CardItem from './CardItem';

Modal.setAppElement('#root');

function CardsPro2() {
  const [selectedImage, setSelectedImage] = useState(null);
  
      const openImage = (src) => {
        setSelectedImage(src);
      };
  
    const closeImage = () => {
      setSelectedImage(null);
    };





  return (
    <div className='cards'>
      <h1 className='h1cards'>Datos Clave Pétalo de Sucre I</h1>
      <div className='cards__paragraphs'>
          <ul>
            <li>Localización: Municipio San Antonio de Palmito (Sucre)</li>
              <li>Área del terreno: 35.3 ha</li>
              <li>Capacidad: 9.9 MW AC (11.9 MWp)</li>
              <li>Producción: 26.65 GWh/Año (P50)</li>
              <li>Punto de conexión: Subestación Coveñas 34.5 kV</li>
              <li>Línea de conexión: 10.6 km a 34.5 kV</li>
              <li>Paneles Solares: 17.134 paneles bifaciales tipo N, HJT, de referencia RISEN RSM132-2-690HDG (690W)</li>
              <li>Inversores: 34 de 350 kW, Referencia SG350HX, Marca SUNGROW</li>
              <li>Trackers: ARCTECH</li>
              <li>Entrada en Operación Comercial: 31 de Diciembre de 2024</li>
              <li>Estado: En Construcción</li>
            </ul>
      </div>

      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/psucrei1.png'
              text='Ubicación PSFV Sucre I  Vista desde  Google Earth con Línea Eléctrica y de Vía Acceso'
              label='BCCY CÓRDOBA'
              openImage={openImage}
            />
            <CardItem
              src='images/psucrei2.png'
              text='Construcción vía de acceso'
              label='BCCY CÓRDOBA'
              openImage={openImage}
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/psucrei3.jpg'
              text='Pruebas de pull out test'
              label='BCCY CÓRDOBA'
              openImage={openImage}
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/psucrei4.jpg'
              text='Puerta de acceso al proyecto'
              label='BCCY CÓRDOBA'
              openImage={openImage}
            />
            <CardItem
              src='images/psucrei5.jpg'
              text='Construcción vía interna'
              label='BCCY CÓRDOBA'
              openImage={openImage}
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/psucrei6.jpg'
              text='Construcción batea vía externa'
              label='BCCY CÓRDOBA'
              openImage={openImage}
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/psucrei7.jpg'
              text='Construcción batea vía externa'
              label='BCCY CÓRDOBA'
              openImage={openImage}
            />
            <CardItem
              src='images/psucrei8.png'
              text='Contenedor de oficinas'
              label='BCCY CÓRDOBA'
              openImage={openImage}
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/psucrei9.png'
              text='Techo contenedor Oficinas con Paneles Solares'
              label='BCCY CÓRDOBA'
              openImage={openImage}
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/psucrei10.jpg'
              text='Estudio de Suelos'
              label='BCCY CÓRDOBA'
              openImage={openImage}
            />
            <CardItem
              src='images/psucrei11.jpg'
              text='Cerramiento'
              label='BCCY CÓRDOBA'
              openImage={openImage}
            />
          </ul>
        </div>
      </div>
      <Modal isOpen={!!selectedImage} onRequestClose={closeImage} className='modal' overlayClassName='modal-overlay'>
              <button className='modal__close' onClick={closeImage}>&times;</button>
              <img src={selectedImage} alt='Imagen Ampliada' className='modal__img' />
            </Modal>
    </div>
  );
}

export default CardsPro2;
