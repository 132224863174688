import React, { useState } from 'react';
import Modal from 'react-modal';
import './Cards.css';
import CardItem from './CardItem';


Modal.setAppElement('#root'); 

function CardsPro1() {
  const [selectedImage, setSelectedImage] = useState(null);
  
    const openImage = (src) => {
      setSelectedImage(src);
    };
  
    const closeImage = () => {
      setSelectedImage(null);
    };


  return (
    <div className='cards'>
      <h1 className='h1cards'>Datos Clave Relleno Sanitario Los Pocitos - BARRANQUILLA</h1>
      <div className='cards__paragraphs'>
          <ul>
              <li>Localización: Municipio de Galapa (Atlántico)</li>
              <li>Área del terreno: 3000 m²</li>
              <li>Capacidad: 5310 kW (5 unidades)</li>
              <li>Producción estimada: 3612 GWh</li>
              <li>Punto de conexión: En relleno sanitario, línea 13.8 kV</li>
              <li>Línea de conexión: 1.3 km a 13.8 kV</li>
              <li>Equipos de generación: Generadores síncronos acoplados a un motor de combustión interna, marca JEMBACHER</li>
              <li>Entrada en Operación Comercial estimada: 14 de diciembre de 2024</li>
              <li>Estado: En construcción</li>
            </ul>
      </div>

      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/pocitos1.jpg'
              text='Equipo de trabajo en el relleno sanitario Los Pocitos'
              label='BCCY HOLDING'
              openImage={openImage} 
            />
            <CardItem
              src='images/pocitos2.jpg'
              text='Transporte de tuberías'
              label='BCCY HOLDING'
              openImage={openImage} 
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/pocitos3.jpg'
              text='Cableado de media tensión'
              label='BCCY HOLDING'
              openImage={openImage} 
            />
            <CardItem
              src='images/pocitos4.jpg'
              text='Descarga de subestación conecteriazada'
              label='BCCY HOLDING'
              openImage={openImage} 
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/pocitos5.jpg'
              text='Transformadores con su cerramiento de seguridad'
              label='BCCY HOLDING'
              openImage={openImage} 
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/pocitos6.png'
              text='Proceso de izaje y descarga de contenedores, uso de grúa PH de 40 toneladas '
              label='BCCY HOLDING'
              openImage={openImage} 
            />
          </ul>
        </div>
      </div>

      <Modal isOpen={!!selectedImage} onRequestClose={closeImage} className='modal' overlayClassName='modal-overlay'>
              <button className='modal__close' onClick={closeImage}>&times;</button>
              <img src={selectedImage} alt='Imagen Ampliada' className='modal__img' />
            </Modal>

    </div>
  );
}

export default CardsPro1;
