import React from 'react';
import { Link } from 'react-router-dom';

function CardItem({ src, text, label, path, openImage, onClick }) {
  return (
    <li className="cards__item">
      {path ? (
        <Link className="cards__item__link" to={path}>
          <figure className="cards__item__pic-wrap" data-category={label}>
            <img
              className="cards__item__img"
              alt="Imagen"
              src={src}
              onClick={(e) => {
                e.stopPropagation(); // Evita que el clic en la imagen afecte la navegación
                openImage && openImage(src);
                onClick && onClick(); // Disparar la notificación
              }}
              style={{ cursor: 'pointer' }}
            />
          </figure>
          <div className="cards__item__info">
            <h5 className="cards__item__text">{text}</h5>
          </div>
        </Link>
      ) : (
        <div className="cards__item__link">
          <figure className="cards__item__pic-wrap" data-category={label}>
            <img
              className="cards__item__img"
              alt="Imagen"
              src={src}
              onClick={() => {
                openImage && openImage(src);
                onClick && onClick(); // Disparar la notificación
              }}
              style={{ cursor: 'pointer' }}
            />
          </figure>
          <div className="cards__item__info">
            <h5 className="cards__item__text">{text}</h5>
          </div>
        </div>
      )}
    </li>
  );
}

export default CardItem;
