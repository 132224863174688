import React, { useState } from 'react';
import Modal from 'react-modal';
import './Cards.css';
import CardItem from './CardItem';

Modal.setAppElement('#root'); 

function CardsPro1() {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (src) => {
    setSelectedImage(src);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className='cards'>
      <h1 className='h1cards'>Datos Clave Pétalo de Córdoba II</h1>
      <div className='cards__paragraphs'>
        <ul>
          <li>Localización: municipio de Chinú, Córdoba</li>
          <li>Área del terreno: 33 ha</li>
          <li>Capacidad: 9.9 MWac (12.75 MWac)</li>
          <li>Producción: 25.1 GWh/año (P95)</li>
          <li>Punto de conexión: Subestación Chinú planta 34.5 kV</li>
          <li>Línea de conexión: 26 KM a 34.5 kV</li>
          <li>Paneles Solares: 22.568 módulos de 565 Wp JA solar bifaciales tipo N</li>
          <li>Inversores: 54 de 220 KW, HUAWEI</li>
          <li>Trackers: 217, SCHLETTER</li>
          <li>Entrada en Operación Comercial: 15 de Diciembre de 2023</li>
          <li>Estado: Operación comercial</li>
        </ul>
      </div>

      <div className='cards__container'>
        <div className='cards__wrapper'>

          <ul className='cards__items'>
            <CardItem 
              src='images/petalocorii1.png' 
              text='Adecuación del terreno para instalación de la planta fotovoltaica' 
              label='BCCY CÓRDOBA' 
              openImage={openImage}
            />
            <CardItem 
            src='images/petalocorii2.jpg' 
            text='Paneles ya instalados' 
            label='BCCY CÓRDOBA' 
            openImage={openImage} 
            />
          </ul>

          <ul className='cards__items'>
            <CardItem 
            src='images/petalocorii3.jpg' 
            text='Vista aérea del parque solar Pétalo de Córdoba II 9.9 MWac' 
            label='BCCY CÓRDOBA' 
            openImage={openImage} 
            
            />
          </ul>

        </div>
      </div>

      <Modal isOpen={!!selectedImage} onRequestClose={closeImage} className='modal' overlayClassName='modal-overlay'>
        <button className='modal__close' onClick={closeImage}>&times;</button>
        <img src={selectedImage} alt='Imagen Ampliada' className='modal__img' />
      </Modal>
    </div>
  );
}

export default CardsPro1;
