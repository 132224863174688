import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

import toast, { Toaster } from 'react-hot-toast';

function CardsHol() {
  const showToast = () => {
    toast.error('No hay información disponible. ❌');
  };

  return (
    <div className='cards'>
      <Toaster position="top-right" reverseOrder={false} />
      <h1 className='h1cards'>Historia BCCY Colombia Holding</h1>
      <div className='cards__paragraphs'>
        <p>La empresa fue creada el 01 de junio de 2021,
            registrada en la Cámara de Comercio de Bogotá
            con NIT 901489949-1. Su accionista en la empresa
            Henan BCCY Energy Co Ltd. Enviromental, a su vez
            BCCY Colombia Holding S.A.S. es la socia mayoritaria
            de la empresa BCCY COLOMBIA HOLDING SAS ESP, la empresa se
            encuentra desarrollando dos proyectos de generación
            de energía a partir de biogás en los rellenos
            sanitarios Colombia, Guayal (Municipio de Yotoco,
            Valle del Cauca) y en Los Pocitos, municipio de Galapa, Atlántico)
            cada uno con 5.3 MW de capacidad instalada.</p>

        <br></br>

        <p>Henan BCCY Environmental Energy Co., Ltd. (código de acciones: 300614)
            se estableció en abril de 2009. Es una empresa respetuosa
            con el medio ambiente que integra investigación y desarrollo,
            producción y operación. Cotizó en el GEM de la Bolsa de Valores
            de Shenzhen el 25 de mayo de 2021. La compañía se ha centrado en
            la utilización integral del biogás y la reducción de las emisiones
            de carbono durante más de diez años. Con el llamado de la política
            de "pico de carbono y neutralidad de carbono", Baichuan Changyin
            continuará cumpliendo con sus responsabilidades sociales y adhiriéndose
            al camino del desarrollo sostenible de reducción de emisiones bajas
            en carbono, para crear una cadena industrial energética respetuosa con
            el medio ambiente que se centre en la generación de energía a partir
            de biogás, el comercio de reducción de emisiones de carbono,
            el almacenamiento móvil de energía, la eliminación de residuos sólidos
            y el desarrollo diversificado. </p>
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/biogas1.png'
              text='RELLENO SANITARIO YOTOCO - CALI'
              label='BCCY COLOMBIA HOLDING'
              onClick={showToast}
            />
            <CardItem
              src='images/biogas2.png'
              text='RELLENO SANITARIO LOS POCITOS - BARRANQUILLA'
              label='BCCY COLOMBIA HOLDING'
              path='/proyects3'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CardsHol;
