import React, { useState } from 'react';
import Modal from 'react-modal';
import './Cards.css';
import CardItem from './CardItem';

Modal.setAppElement('#root'); 

function Cards() {


  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (src) => {
    setSelectedImage(src);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };



  return (
    <div className='cards'>
      <h1 className='h1cards'>Historia BCCY Córdoba</h1>
      <div className='cards__paragraphs'>
        <p>La empresa fue creada el 24 de septiembre de 2021, registrada en
          la Cámara de Comercio de Bogotá con NIT 901.524.581-5.
          Sus accionistas son Henan BCCY Environmental Co Ltd.
          y BCCY Colombia Holding S.A.S. La compañía está desarrollando tres proyectos
          fotovoltaicos de 9.9 MW en los municipios de Chinú (Córdoba),
          San Antonio de Palmito (Sucre) y Chiriguaná (Cesar)</p>
      </div>

      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items'>
            <CardItem
              src='images/sembraton.png'
              text='Sembratón de árboles nativos con los niños, adolescentes y adultos del Cabildo Indígena Los Algarrobos'
              label='BCCY CÓRDOBA ACTUALIDAD'
              
              openImage={openImage} 
              // path='/proyects4'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/petalocorii4.jpg'
              text='PROYECTO PÉTALO DE CÓRDOBA II'
              label='BCCY CÓRDOBA'
              path='/proyects1'
            />
            <CardItem
              src='images/psucrei9.png'
              text='PROYECTO PÉTALO DE SUCRE I'
              label='BCCY CÓRDOBA'
              path='/proyects2'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/cordoba1.png'
              text='PÉTALOS DE CESAR I'
              label='BCCY CÓRDOBA'
              path='/proyects4'
            />
          </ul>
        </div>
      </div>

      <Modal isOpen={!!selectedImage} onRequestClose={closeImage} className='modal' overlayClassName='modal-overlay'>
              <button className='modal__close' onClick={closeImage}>&times;</button>
              <img src={selectedImage} alt='Imagen Ampliada' className='modal__img' />
            </Modal>
    </div>
  );
}

export default Cards;
